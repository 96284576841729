<template>
   <AmcText>
      <AmcText v-for="(item, index) in items" :key="index" class="sidebar flex-column p-0">
         <li v-if="item.onlySystemManager">
            <router-link :to="item.to" class="nav-item text-decoration-none">
               <AmcSidebarItem :item="item" />
            </router-link>
         </li>
      </AmcText>
   </AmcText>
</template>

<script>
import AmcSidebarItem from './AmcSidebarItem';

export default {
   name: 'AmcSidebar',
   components: { AmcSidebarItem },
   props: {
      items: {
         type: [Array, Object],
         default: () => [],
      },
   },
};
</script>

<style scoped lang="scss">
.sidebar {
   margin-top: 40px;
}
.settings-style {
   margin-top: 10rem !important;
   border-top: 2px solid #4093f5;
}
</style>
