<template>
   <li class="nav-item">
      <div
         class="nav-icon"
         :class="{
            'bg-white rounded-5':
               item.to === '/' ? this.$route.path === `${item.to}` : this.$route.path.startsWith(`${item.to}`),
         }"
      >
         <component
            :is="item.icon"
            :class="{
               'icon-color':
                  item.to === '/' ? this.$route.path === `${item.to}` : this.$route.path.startsWith(`${item.to}`),
            }"
         ></component>
      </div>
      <p class="item-text">{{ $t(`sideBar.${item.text}`) }}</p>
   </li>
</template>

<script>
export default {
   name: 'AmcSidebarItem',
   props: {
      item: {
         type: [Array, Object],
         default: () => [],
      },
   },
};
</script>

<style lang="scss" scoped>
.nav-item {
   text-align: center;
   display: block;

   & .nav-icon {
      padding: 15px 0;
      margin: 0 20px 0px 30px;

      &:hover {
         background: rgba(113, 175, 250, 0.52);
         border-radius: 5px;
      }
   }

   & .item-text {
      color: #ffffff;
      font-size: 13px;
      margin: 0 0 20px 10px;
   }
}
.icon-color {
   fill: #0d6efd;
}
</style>
